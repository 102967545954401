import React, { useEffect } from "react";
import Soc2 from "../Home/WhyCustomerLoveWokelo";
import TrustedBy from "../Home/TrustedBy";
import Form from "./Form";
import Footer from "../Footer";
import WhyCustomerLovesWokeloCarousel from "../Home/WhyCustomerLoveWokeloCarousel";
import TrustedByCopy from "../Home/TrustedbyCopy";
import HowItWorks from "../Home/HowItWorks";
import WhyWokeloPE from "./WhyWokelo";
import TrustedbyPE from "../Home/TrustedbyPE";

type Props = {};

const PrivateEquityAccess = (props: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex flex-col relative">
      <Form />
      <TrustedbyPE leftAligned={true} />
      <WhyWokeloPE />
      <HowItWorks className="" />
      <WhyCustomerLovesWokeloCarousel
        title="Why our customers love Wokelo"
        titleClassName="text-[24px] leading-[28px] md:text-[36px] md:leading-[43px]"
        subTitleClassName="md:w-full"
        subtitle={<>Don't just take our word for it. Hear from those who have witnessed <br/> the magic of Wokelo.AI firsthand.</>}
      />
      <Footer theme="dark"></Footer>
    </div>
  );
};

export default PrivateEquityAccess;
