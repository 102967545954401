import React, { useState, useEffect } from "react";
import Paragraph from "../Paragraph";
import { s } from "./utils";
import { AnimatePresence, LayoutGroup, motion, useInView } from "framer-motion";
import { useMediaQuery, useTheme } from "@mui/material";
import Navbar from "../Navbar";
import "./Landing4.scss";
import Vimeo from "@u-wave/react-vimeo";

const ANIMATION_DURATION = 2;

const opacity = {
  1: 1,
  2: 0.6,
  3: 0.25,
  4: 0,
};

const gap = {
  1: "60vw",
  2: "57vw",
  3: "52vw",
  4: "47vw",
};

type Props = {
  setLanding?: any;
  landing?: any;
  scrollHeight?: any;
  setScrollHeight?: any;
  leftAligned?: boolean;
};

function scrollTo55Percent() {
  const viewportHeight = window.innerHeight;
  const scrollDistance = 0.6 * viewportHeight;
  window.scrollTo({
    top: scrollDistance,
    behavior: "smooth",
  });
}

const logos = ['/Berkshire_Partners.svg', '/ey.svg', '/Insight_Partners.svg', '/KPMG.svg', '/premji.svg']

const TrustedbyPE = React.forwardRef(
  (
    {
      setLanding,
      landing,
      scrollHeight,
      setScrollHeight,
      leftAligned = false,
    }: Props,
    ref?: any
  ) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [videoPaused, setVideoPaused] = useState(true);

    const handlePlayVideo = () => {
      setVideoPaused(false);
    };

    const handleStopVideo = () => {
      document
        .getElementById("video-vimeo")
        ?.querySelectorAll("iframe")
        .forEach((v) => {
          v.src = v.src;
        });

      setVideoPaused(true);
    };

    useEffect(() => {
      if (scrollHeight == 4) {
        if (videoPaused) {
          handlePlayVideo();
        }
      } else if (!videoPaused) {
        handleStopVideo();
      }
    }, [scrollHeight]);

    const minHeight = `min-h-[calc(${gap[scrollHeight]}-20vh)]`;

    return (
      <section
        className={`w-full flex flex-col relative ${s.heroPaddings}`}
        style={{ paddingTop: 0, paddingBottom: leftAligned ? 10 : 0 }}
      >
        <div
          className={`${leftAligned
            ? "grid mt-10 grid-cols-1 md:grid-cols-5 min-h-[15vh] lg:min-h-[20vh]"
            : "flex flex-col"
            }`}
        >
          <div
            className={`flex justify-center mb-4 ${leftAligned ? "col-span-1 md:col-span-5" : ""
              }`}
          >
            <div className="w-full flex flex-col lg:flex-row items-center justify-between">
              <Paragraph
                className={`${leftAligned ? "text-center md:text-left" : "text-center"
                  } text-[#525252] whitespace-nowrap mb-8 md:mb-0`}
              >
                Trusted by industry leaders
              </Paragraph>
              <div
                className={`w-fit flex flex-row flex-wrap justify-center  items-center ipadGrid gap-8 xl:gap-16`}
              >
                {
                  logos.map((logo) => {
                    return <div className={leftAligned ? "" : ""} key={logo}>
                      <picture>
                        <source
                          srcSet={logo}
                          type="image/webp"
                        />
                        <img
                          src={logo}
                          alt="776"
                          className="z-30 object-scale-down h-auto my-auto"
                        />
                      </picture>
                    </div>
                  })
                }

              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default TrustedbyPE;
