import PrivateEquityAccess from "../core/components/PrivateEquity/PrivateEquityAccess";
import TradedVCAccess from "../core/components/tradedvcAccess/TradedVCAccess";

type Props = {};

const PrivateEquity = (props: Props) => {
  return <PrivateEquityAccess></PrivateEquityAccess>;
};

export default PrivateEquity;
