import React, { useState } from "react";
import Heading from "../Heading";
import Paragraph from "../Paragraph";
import StarIcon from "../../icons/Star";
import "./howItWorks/howItWorks.css";
import Input from "../Input";

type Props = { className?: string };

const HowItWorks = React.forwardRef((props: Props, ref: any) => {
  const { className = '' } = props
  const [email, setEmail] = useState("");

  return (
    <section id="howitworks" className={`relative bg-[#202020] my-[70px] ${className}`}>
      <div
        className={` flex flex-col px-[20px] sm:px-[20px] md:px-[20px] lg:px-[120px] xl:px-[120px] py-[40px] md:py-[80px]`}
      >
        <div className="flex justify-between items-center text-w-light-black">
          <Heading className="text-w-light-white">How it works</Heading>
          <p className="text-[18px] text-w-light-white">/////</p>
        </div>
        <div className="">
          <Paragraph className="mt-2 text-w-light-white">
            Select your sources, define analysis, and you're presented with a
            refined output like magic
          </Paragraph>
        </div>
        {/* <div className="my-[60px] hidden lg:grid lg:grid-cols-5 gap-4">
          <div className="flex flex-between">
            <p className="text-w-light-white text-[26px]">
              Comprehensive Data Curation
            </p>
          </div>
          <div className="flex justify-end mr-4">
            <StarIcon />
          </div>
          <div className="">
            <ul className="md:list-disc list-outside px-1">
              <li className="text-w-light-white">
                <p className="text-[16px] leading-[19px]">
                  {" "}
                  Leverage Wokelo’s extensive library of 500+ pre-integrated
                  public and premium datasets{" "}
                </p>
              </li>
            </ul>
          </div>
          <div>
            <ul className="md:list-disc list-outside px-1">
              <li className="text-w-light-white">
                <p className="text-[16px] leading-[19px]">
                  {" "}
                  Connect your internal datasets or upload multiple files for
                  secure data synthesis{" "}
                </p>
              </li>
            </ul>
          </div>
          <div>
            <ul className="md:list-disc list-outside px-1">
              <li className="text-w-light-white ">
                <p className="text-[16px] leading-[19px]">
                  {" "}
                  Up-to-date and live insights with citations from tier-1
                  sources{" "}
                </p>
              </li>
            </ul>
          </div>
        </div> */}
        <div className="mt-[60px] hidden lg:block aligncenter w-[75%]">
          <picture className="relative">
            <source srcSet="/TreeV3.png" type="image/webp" />
            <img
              src="/TreeV3.png"
              alt="Hero"
              className="z-30 relative object-scale-down w-full h-auto my-auto"
            />
            <div className="glowing-circle"></div>
          </picture>
        </div>
        <div className="mt-[100px] hidden lg:block">

          <div className="flex justify-center my-4">
            <Input
              className="border-[1px] border-[#ffffff4d] bg-transparent "
              placeholder="Enter Business Email"
              inputClassName={"text-w-black placeholder:text-[#FFFFFF]"}
              value={email}
              onInput={(e) => {
                setEmail(e.currentTarget.value);
              }}
              variant="secondary"
              btnLabel="Book Demo"
            />
          </div>


        </div>
        <div className="mt-6 block lg:hidden">
          <div className="flex justify-center">
            <picture className="relative">
              <source srcSet="/TreeV2Mob.png" type="image/webp" />
              <img
                src="/TreeV2Mob.png"
                alt="Hero"
                className="z-30 relative w-[80vw]"
              />
              <div className="glowing-circle-mobile"></div>
            </picture>
          </div>

          <div className="flex justify-center my-4">
            <Input
              className="border-[1px] border-[#ffffff4d] bg-transparent placeholder:text-[#FFFFFF]"
              placeholder="Enter Business Email"
              inputClassName={" placeholder:text-[#FFFFFF]"}
              value={email}
              onInput={(e) => {
                setEmail(e.currentTarget.value);
              }}
              variant="primary"
              btnLabel="Book Demo"
            />
          </div>

        </div>
      </div>
    </section>
  );
});

export default HowItWorks;
