import React from "react";
// import FacebookIcon from '../icons/Facebook';
import LinkedInIcon from "../icons/LinkedIn";
import WokeloIcon from "../icons/Wokelo";
// import TwitterIcon from '../icons/Twitter';
import { Link } from "react-router-dom";
import TwitterIcon from "../icons/Twitter";
import MapIcon from "../icons/Map";
import MailIcon from "../icons/Mail";

type Props =  {theme?: 'light' | 'dark'}
const Footer = (props?: Props) => {
  const { theme } = props || {}

  const textClass = ` text-[16px] leading-[16px] hover:text-w-light-gray transition-all ${theme === 'dark' ? '!text-white' : 'text-w-dark-black'}`
  return (
    <div className={`flex flex-col px-[20px] sm:px-[20px] md:px-[40px] lg:px-[120px] xl:px-[120px] py-[30px] md:py-[50px] border-t border-t-[rgba(0,0,0,0.10)] ${theme ==='dark' ?'bg-[#202020]' : ''}`}>
      <div className="flex flex-col md:flex-row justify-between items-center gap-6 md:gap-20">
        <div className="bg-[0003] pb-4">
          <WokeloIcon className="w-[200px] sm:w-full h-auto" theme={theme}/>
        </div>
        <div className="w-full md:w-[70%]">
          <div className="flex flex-col-reverse md:flex-col">
            <div className="flex-row items-center border-y-[1px] border-y-[#777] py-8 md:py-0 md:border-y-0  gap-12 lg:flex xl:flex 2xl:flex">
              <div className={`flex flex-row items-center flex-wrap gap-6 md:gap-8 ${theme === 'dark' ? '!text-white' : ''}`}>
                <a
                  href="/#product"
                  className={textClass}
                  onClick={() => {
                    window.scrollTo({
                      top: document?.getElementById("product")?.offsetTop,
                      behavior: "smooth",
                    });
                  }}
                >
                  Product
                </a>

                <a
                  href="/#solutions"
                  className={textClass}
                  onClick={() => {
                    window.scrollTo({
                      top: document?.getElementById("solutions")?.offsetTop,
                      behavior: "smooth",
                    });
                  }}
                >
                  Solutions
                </a>

                <a
                  href="/#soc2"
                  className={textClass}
                  onClick={() => {
                    window.scrollTo({
                      top: document?.getElementById("soc2")?.offsetTop,
                      behavior: "smooth",
                    });
                  }}
                >
                  Security
                </a>
                <a
                  href="/privacy-policy"
                  target="_blank"
                  className={textClass}
                  // onClick={() => {
                  //   window.scrollTo({ top: 0, behavior: 'smooth' });
                  // }}
                >
                  Privacy
                </a>
                <a
                  href="/terms-and-conditions"
                  target="_blank"
                  className={textClass}
                  // onClick={() => {
                  //   window.scrollTo({ top: 0, behavior: "smooth" });
                  // }}
                >
                  T&C
                </a>
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-4 justify-between md:border-y-[1px] md:border-y-[rgba(0,0,0,0.10)] my-10 py:8 md:py-10">
              <div className="flex flex-col justify-start items-start gap-4">
                <div className="flex gap-3">
                  <MapIcon theme={theme}/>
                  <p className={`text-[14px] md:text-[16px] ${theme === 'dark' ? 'text-[#E3D6C7]' :'text-black'}`}>
                    {/* Wokelo Inc. Seattle, WA, USA */}
                    92 Lenora Street, Seattle, Washington 98121
                  </p>
                </div>
                <a href="mailto: support@wokelo.ai">
                  <div className="flex gap-3">
                    <MailIcon theme={theme}/>
                    <p className={`text-[14px] md:text-[16px] ${theme === 'dark' ? 'text-[#E3D6C7]' :'text-black'}`}>
                      support@wokelo.ai
                    </p>
                  </div>
                </a>
              </div>
              <div className="flex gap-4 items-center">
                <a
                  href="https://www.linkedin.com/company/wokelo-ai/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <LinkedInIcon theme={theme}/>
                </a>
                <a
                  href="https://twitter.com/WokeloAi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterIcon theme={theme}/>
                </a>
              </div>
            </div>
          </div>

          {/* Copyright */}
          <div className="mt-8 md:mt-4">
            <p className="text-[#777] font-normal text-[14px] leading-[16px]">
              2024 Wokelo AI | Wokelo Inc. All rights reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
