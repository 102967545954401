import * as React from "react";

function Wokelo(props: {theme?: 'dark' | 'light'} & React.SVGProps<SVGSVGElement>) {
  const {theme} = props||{}
  const logoUrl = theme === 'dark' ? "/logo_white.svg" : "/logo_black.svg"
  return (
    <picture>
      <source srcSet={logoUrl} type="image/webp" />
      <img
        src={logoUrl}
        alt="Hero"
        className="z-30 w-[254px] h-[148px]"
      />
    </picture>
  );
}

const WokeloIcon = React.memo(Wokelo);
export default WokeloIcon;
