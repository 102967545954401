import { InputHTMLAttributes, forwardRef, useRef, useState } from "react";
import Button, { ButtonVariant } from "./Button";
import ArrowIcon from "../icons/Arrow";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { CircularProgress, useMediaQuery } from "@mui/material";
import { Api } from "../hooks/Api";
import CheckIcon from "@mui/icons-material/Check";

export type InputProps = {
  disableOutline?: boolean;
  inputClassName?: String;
  value?: string;
  variant?: ButtonVariant;
  ref?: any;
  btnLabel?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const Input = forwardRef(
  (
    {
      className,
      value,
      disableOutline = false,
      inputClassName,
      variant,
      btnLabel,
      ...props
    }: InputProps,
    ref?: any
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const matches = useMediaQuery("(min-width:1200px)");
    const sessionSource = JSON.parse(
      sessionStorage.getItem("source") as string
    );

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const navigate = useNavigate();

    const onSubmit = async () => {
      setLoading(true);
      const payload = {
        username: value,
        source: sessionSource ?? "",
      };

      Api.requestAccess(payload)
        .then((res) => {
          console.log(res?.message);
          if (res?.message) {
            setSuccess(true);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });

      navigate(`/request-access?email=${value}`);
    };

    return (
      <>
        <div
          className={`w-[335px] md:w-[475px] h-[60px] md:h-[80px] rounded-[50px] transition-all px-2 border flex flex-row items-center justify-between ${className}`}
        >
          <input
            ref={ref}
            className={`w-[160px] md:w-[250px] text-[12px] md:text-[16px] bg-transparent focus:outline-0 focus:border-none
					 outline-none border-none px-4 lg:px-[1.75.vw]  ${inputClassName}`}
            value={value}
            {...props}
          ></input>
          {/* <Link
          to="https://app.wokelo.ai/request-access"
          target="_blank"
          rel="noopener noreferrer"
        > */}
          <Button
            type="button"
            variant={variant}
            className={`w-[151px] md:w-[212px] h-[45px] md:h-[64px] flex items-center cursor-pointer justify-center`}
            onClick={onSubmit}
            disabled={!value}
          >
            <div className="w-full flex justify-between items-center gap-2">
              <p>{btnLabel||'Request Access'}</p>
              {loading ? (
                <CircularProgress
                  sx={{
                    color: variant === "primary" ? "#ffffff" : "#343434",
                    fontSize: 14,
                    width: "20px !important",
                    height: "20px !important",
                  }}
                />
              ) : success ? (
                <CheckIcon
                  sx={{
                    color: variant === "primary" ? "#ffffff" : "#343434",
                    fontSize: 14,
                    width: "20px !important",
                    height: "20px !important",
                  }}
                />
              ) : (
                <ArrowIcon className={`w-[18px] md:w-[21px]`} />
              )}
            </div>
          </Button>
          {/* </Link> */}
        </div>
      </>
    );
  }
);

export default Input;
