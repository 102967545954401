import * as React from "react";

function Mail(props: {theme?: 'dark' | 'light'} & React.SVGProps<SVGSVGElement>) {
  const {theme} = props||{}
  const logoFill = theme === 'dark' ? "#E3D6C7" : "#343434"

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M2.4974 2.5H17.4974C17.9576 2.5 18.3307 2.8731 18.3307 3.33333V16.6667C18.3307 17.1269 17.9576 17.5 17.4974 17.5H2.4974C2.03716 17.5 1.66406 17.1269 1.66406 16.6667V3.33333C1.66406 2.8731 2.03716 2.5 2.4974 2.5ZM10.0479 9.73575L4.70341 5.19808L3.62471 6.46858L10.0583 11.9309L16.3761 6.46347L15.2854 5.2032L10.0479 9.73575Z"
        fill={logoFill}
      />
    </svg>
  );
}

const MailIcon = React.memo(Mail);
export default MailIcon;
