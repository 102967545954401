import * as React from "react";

function Twitter(props: {theme?: 'dark' | 'light'} & React.SVGProps<SVGSVGElement>) {
  const {theme} = props||{}
  const logoFill = theme === 'dark' ? "#E3D6C7" : "#2D2D2D"

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="26"
      viewBox="0 0 23 26"
      fill="none"
	  {...props}
    >
      <path
        d="M3.85714 3C2.28125 3 1 4.28125 1 5.85714V20.1429C1 21.7188 2.28125 23 3.85714 23H18.1429C19.7188 23 21 21.7188 21 20.1429V5.85714C21 4.28125 19.7188 3 18.1429 3H3.85714ZM17.1205 6.75L12.4866 12.0446L17.9375 19.25H13.6696L10.3304 14.8795L6.50446 19.25H4.38393L9.33929 13.5848L4.11161 6.75H8.48661L11.5089 10.7455L15 6.75H17.1205ZM15.433 17.9821L7.84821 7.95089H6.58482L14.2545 17.9821H15.4286H15.433Z"
        fill={logoFill}
      />
    </svg>
  );
}

const TwitterIcon = React.memo(Twitter);
export default TwitterIcon;
